@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;

  &__location {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
  }

  &__info {
    font-size: 1.25rem;
    padding-bottom: 3rem;
    @include desktop {
      margin-right: 7rem;
    }
  }

  &__hours {
    display: flex;
    flex-direction: row;   
    padding: 1rem 0rem 2rem;

    @include desktop{
      column-gap: 1rem;
    }


    &--left {
      border-right: 3px solid white;
      font-family: "Acumin Pro";
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include desktop {
        padding-right: 4rem;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      column-gap: 1rem;
    }

    &--info {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      text-align: end;
      font-size: 1.25rem;
      @include desktop{
          font-size: 1.75em;
      }
    }
  }
  &__name {
    letter-spacing: 0.5rem;
    justify-content: center;
    align-content: flex-end;
    font-size: 2rem;
    display: inline-block;
    align-self: center;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 1em;
  }

  &__title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  &__subtitle {
    
    font-size: 1.5em;
    @include desktop{
        font-size: 2.5em;
    }

    margin-bottom: 0.5em;
    text-transform: uppercase;
    letter-spacing: 10px;
  }

  &__email{
padding-right: 0.5rem;
  }
  &__email{
    font-size: 1.5rem;
    @include desktop {
      font-size: 1.5rem;
    }
    line-height: 1.6;
  }

  .contact__hours {
    justify-content: space-between;
    @include desktop {
      margin-top: 10rem;
      margin-bottom: 3em;
    }
  }
}

.contact p{
    margin: 0;

}
