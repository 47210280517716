@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.prices {
  font-family: "Acumin Pro";
  font-style: normal;
  font-weight: normal;
  &_hero {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/facial.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;

    height: 100vh;

  }
  &_title {
    text-align: center;
    font-size: 7rem;
    padding-top: 15%;
    letter-spacing: 0.5rem;
    @media (max-width: 768px) {
        padding-top: 50%;
        font-size: 4rem;
      }
  }
  &_subtitle {
    text-align: center;
    font-size: 2rem;
    padding-top: 4%;
    letter-spacing: 0.5rem;
    @media (max-width: 768px) {
        padding-top: 50%;
        font-size: 2rem;
      }
  }
  &_container {
    @include desktop {
      margin: 1vw 10vw 4rem;
    }
  }
  &_catagory {
    border-bottom: 1px solid white;
    padding: 3rem 0rem 4rem;
    @media (max-width: 768px) {
        padding: 3rem 1em 4rem;
      }
    &:last-child {
      border-bottom: none; // Ensure that the last link doesn't have extra space below it
    }
    
  }
  &_item {
    margin: 3rem;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0.5rem;
          @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    
  }
  &_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
    &-item{
        @media (max-width: 768px) {
            font-size: 1rem;
          }
    }
    &-prices {
      color: rgb(189, 189, 189);
      font-family: "Montserrat";
      font-weight: 300;
      text-align: end;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
}
