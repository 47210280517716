@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;


.footer {
  display: none;
  flex-direction: column;
  padding: 2rem 4rem;
  background-color: black;
  color: #fff;
  column-gap: 5rem;
  @include desktop{
    display: flex;
  }
  &_top{
    display: flex;
    justify-content: space-between;
  }

  &_column {
    display: flex;
    flex-direction: column;
    width: 20%;
      p{
        margin: 0%;
        padding: 0%;
      }
      h4 {
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.5rem;
        color: rgb(223, 190, 106);
      }

      a {
          color: #fff;
          margin-bottom: 5px;
          text-decoration: none;
          &:hover{
            transition-delay: 0.5s;
            color: rgb(223, 190, 106);
          }
      }
      &-one,&-two{
        display: flex;
        flex-direction: column;
        
      }
  }
  &_columns{
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
  }

  &_link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.7rem;

  }
  &_logo {
    font-family: "Noto Serif Display", serif;
    justify-content: center;
    align-content: flex-end;
    display: inline-block;
    align-self: center;
    font-size: 4rem;
    color: white;
    padding: 0%;
  }
  &_sublogo {
    text-align: center;
    color: rgb(243, 207, 115);
    padding-left: 1rem;
    align-content: center;
    justify-content: center;
    display: grid;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
  }

  &_bottom{
    h4 {
      font-family: Helvetica, sans-serif;
      font-size: 0.9rem;
      text-align: center;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      color: white;
      margin-top: 4rem;
      span{
        color:rgb(243, 207, 115);
      }
    }
  }
}


.hours{
  &_item{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
  }
}