@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.gallery{
    font-family: "Acumin Pro";
        font-style: normal;
        font-weight: normal;
    &_hero{
        background: 
        linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.5)), 
        url('../../assets/images/main1.jpg');
    background-size: cover;
        background-position: center; 
        background-repeat: no-repeat; 
        background-attachment: fixed;

        height: 100vh; 
        @include desktop{
    }
    }
    &_title{
        text-align: center;
        font-size: 7rem;
        padding-top: 20%;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
            padding-top: 50%;
            font-size: 4rem;
          }
    }
    &_subtitle{
        text-align: center;
        font-size: 2rem;
        padding-top: 4%;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
            padding-top: 50%;
            font-size: 2rem;
          }
    }
}

