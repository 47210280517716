
body {
    margin: 0;
  }
  
  .background {
    align-items: center;
    background: black;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100vw;
  }
  
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 600vh;
    justify-content: space-around;
    text-align: center;
    text-transform: uppercase;
    width: 100vmin;
    @media (max-width: 768px) {
        height: 300vh;
      }
    
  }
  
  .image {
    background: white;
    box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.25);
    border: 15px solid white;
    border-width: 1vmin 1vmin 10vmin 1vmin;
    height: 70vmin;
    overflow: hidden;
    width: 70vmin;
    
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  