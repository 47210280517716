@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.about {
  &_hero {
    font-family: "Acumin Pro";
    font-style: normal;
    font-weight: normal;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/cover.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    @include desktop {
    }
  }
  &_title {
    text-align: center;
    font-size: 7rem;
    padding-top: 20%;
    letter-spacing: 0.5rem;
    @media (max-width: 768px) {
      padding-top: 50%;
      font-size: 3rem;
    }
  }

  &_subtitle {
    text-align: center;
    font-size: 2rem;
    padding-top: 4%;
    letter-spacing: 0.5rem;
  }
  &_header {
    font-size: 4rem;
    @include desktop {
      font-size: 7rem;
    }
    text-align: center;

    letter-spacing: 0.5rem;
  }
  &_section {
    margin: 5rem 2rem 5rem;
    @include desktop {
      margin: 10rem;
    }
  }
  &__closing {
    @media (max-width: 768px) {
      padding: 6rem 1.5rem;
    }
    @include tablet {
      padding: $tablet-layout;
    }
    @include desktop {
      padding: 5rem 10rem;
    }
    background-color: rgb(243, 207, 115);

    text-align: center;
    &--title {
      text-align: center;
      margin: 0rem;
      font-size: 3rem;
      color: black;
    }
    &--content {
      color: black;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
  &__booking {
    display: flex;
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--hook {
      font-size: 1.5rem;
      margin: 0%;
      color: black;
    }
    &--link {
      margin-top: 1.5rem;
      display: inline-block;
      justify-items: center;
      overflow: hidden;
      margin: 0;
      padding: 0%;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: white;
      }
    }
    &--text {
      font-size: 1.5rem;
      margin: 0%;
      color: black;
    }
  }
  &_content {
    font-size: 1rem;
    font-weight: 300;
    text-align: left;

    @include desktop {
      font-size: 1.5rem;
    }
  }
  &_story {
    font-size: 1.25rem;
    font-weight: 200;
    text-align: left;
    & span {
      color: rgba(223, 190, 106);
    }
    @include desktop {
      font-size: 2.2rem;
    }
  }
}

.about__section {
  margin: 5rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop {
    margin: 5rem 10rem 10rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .about_person {
    width: 100%; // For mobile
    margin: 1rem 1rem 4rem; // Some space between each person
    @include desktop {
      width: calc(33.33% - 2rem); // For desktop, 3 in a row
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .about_picture {
      width: 100%;
      max-width: 250px;
      height: 250px; // Set a fixed height
      border-radius: 10px;
      object-fit: cover; // This will cover the content box and crop if necessary

      @include desktop {
        max-width: 400px; // Width for desktop
        max-height: 400px; // Height for desktop
      }
    }

    .about_name {
      text-align: center;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      font-size: 1.5rem;

      @include desktop {
        font-size: 1.25rem;
      }
    }
    .about_title {
      text-align: center;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      font-size: 1rem;
      margin: 0;
      padding: 0%;

      @include desktop {
        font-size: 1rem;
      }
    }

    .about_bio {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;

      @include desktop {
        flex-direction: row;
      }
    }
  }

  @include desktop {
    .about_person {
      &:first-child {
        // Special style for Dr. Han
        width: 100%;
        text-align: center;
      }
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  @include desktop {
    width: 80vw;
  }
  background-color: white;
  color: black;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  button {
    align-self: flex-end;
    padding: 0.5rem 1rem;
    border: none;
    background-color: rgb(243, 207, 115);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgb(223, 190, 106);
    }
  }
}
