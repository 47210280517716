@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.nav {
  display: none;
  padding: 2rem 5rem;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: black;
  @include desktop{
    display: block;
  }
}
.header {
  position: sticky;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.5rem;
  top: 0px;
  z-index: 1;
  background-color: black;
  display: none;
  padding: 0;
  color: white;
  padding-bottom: 1rem;
  border-bottom: 2px solid white;
  flex-direction: row;
  justify-content: space-between;

  @include desktop {
    display: flex;
  }

  &_left {
    display: flex;
  }

  &_center {
    display: flex;
    justify-content: center;
    column-gap: 3%;
    vertical-align: middle;
    align-items: center;
  }

  &_right {
    display: flex;
    justify-content: center;
    font-family: Helvetica, sans-serif;
  }

  &_link {
    display: inline-flex;
    justify-content: flex-end;
    align-content: flex-end;
    text-decoration: none;
    font-size: 0.8rem;

    &:hover {
      transition-delay: 0.2s;
      color: rgba(223, 190, 106);
      text-decoration: none;
    }

    &.active {
      color: rgb(243, 207, 115);
      text-decoration: none;
    }
  }
  &_item {
    display: inline-block;
    align-self: center;
    &-service {
      color: black;
      display: block;
      letter-spacing: 0.2rem;
    }
  }
  &_logo {
    font-family: "Noto Serif Display", serif;
    justify-content: center;
    align-content: flex-end;
    display: inline-block;
    align-self: center;
    font-size: 2rem;
    color: white;
    padding: 0%;
    margin: 0%;
  }
  &_sublogo {
    color: rgb(243, 207, 115);
    font-size: 0.7rem;
    align-content: center;
    justify-content: center;
    display: grid;
  }
  &_button {
    padding: 0.625rem 1.875rem;
  }
}

.header_button,
.header_btn-container {
  white-space: nowrap;
  background: transparent;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.7rem;
  color: rgba(223, 190, 106, 0.7);
  border-radius: 0;
  background: linear-gradient(
    270deg,
    rgba(223, 190, 106, 0.8),
    rgba(147, 111, 52, 0.8),
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-position: 1% 50%;
  background-size: 300% 300%;
  text-decoration: none;
  border: none;
  border: 1px solid rgba(223, 190, 106, 0.3);
  transition: all 0.7s ease-in-out;
}

.header_btn-container .header_button:hover {
  color: #fff;
  border: 1px solid rgba(223, 190, 106, 0.3);
  background-position: 99% 50%;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.hr {
  width: 50%;
}

a:visited {
  color: white; /* Inherits the color of the unvisited link */
  text-decoration: none; /* Removes underline */
}

.dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
  }
}

.dropdown-divider {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.dropdown-heading {
  color: rgba(205, 174, 96, 0.7);
  letter-spacing: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  margin:0.5rem 0rem;
}

.dropdown-content {
  white-space: nowrap;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10rem;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  .header_link {
    display: block;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0; // Ensure that the last link doesn't have extra space below it
    }
  }
}
