@use "./variables" as *;

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin mobile-layout {
  padding: 1.6rem 3.2rem 6rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

@mixin highlight {
  color:  #E8D7CE;
  text-transform: uppercase;
  font-size: 1.7rem;
  letter-spacing: 1px;
  font-weight: 600;
  @include desktop{
    font-size: 2.7rem;

  }
}
@mixin mobile-components {
  margin: 1rem 0;
}