@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;



.doubleEyelid{
    font-family: "Acumin Pro";
        font-style: normal;
        font-weight: normal;
    &_hero{
        background: 
        linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)), 
        url('../../assets/images/doubleeyelid.jpg');
        background-position: center; 
        background-repeat: no-repeat; 
        background-attachment: fixed;
        background-size: cover; 
        height: 100vh;  
    
    }
    &_title{
        text-align: center;
        font-size: 7rem;
        padding-top: 20%;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
            padding-top: 50%;
            font-size: 3rem;
          }
    }

    &_header{
        text-align: center;
        margin: 9rem 0rem 0rem;
        font-size: 3rem;
    }
    &_subtitle{
        text-align: center;
        font-size: 2rem;
        padding-top: 4%;
        letter-spacing: 0.5rem;
        @media (max-width: 768px) {
            font-size: 1rem;
          }
    }
    &_section{
        margin: 10rem;
        font-size: 1.5rem;
        line-height: 3rem;
        @media (max-width: 768px) {
            font-size: 1rem;
            line-height: 2rem;
            margin: 3rem ;
          }
    }
}
