@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

@keyframes fadeInFromBlack {
    from {
      opacity: 1;
      background-color: black;
    }
    to {
      opacity: 0;
      background-color: transparent;
    }
  }
  

.home {
  &_hero {
    position: relative;
    background-image: url("../../assets/images/main3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      animation: fadeInFromBlack 5s forwards;
      z-index: 0;
    }

    .home_content {
      position: relative;
      z-index: 2;

      &-top {
        display: flex;
        flex-direction: column;
      }

      &-logo {
        text-transform: uppercase;
        text-align: center;
        font-size: 4rem;
        padding-top: 30%;
        letter-spacing: 0.9rem;
        font-family: "Noto Serif Display", serif;
        font-weight: 400;
        justify-content: center;
        align-content: flex-end;
        display: inline-block;
        align-self: center;
        color: white;
        padding: 0%;
        margin: 0%;

        @include desktop {
          font-size: 7rem;
        }
      }

      &-sublogo {
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.5rem;
        color: rgba(223, 190, 106);
        text-align: center;
      }

      &-subtitle {
        text-align: center;
        font-size: 1rem;
        padding-top: 4%;
        font-weight: 300;
        letter-spacing: 0.3XXrem;
        justify-self: flex-end;

        @include desktop {
          font-size: 2rem;
        }
      }
    }
  }

  .services {
    @include desktop {
      margin: 10rem;
    }

    &_header {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 2rem;
      letter-spacing: 0.5rem;
      font-weight: 300;
      text-transform: uppercase;
      font-family: Helvetica, sans-serif;

      @include desktop {
        font-size: 4rem;
      }
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include desktop {
        flex-direction: row;
        justify-content: space-between;
      }

      .services-item {
        @include desktop {
          width: calc(33.33% - 10px);
        }

        margin-bottom: 20px;

        a {
          display: block;
          text-align: center;
          text-decoration: none;

          h3 {
            color: white;
            margin-top: 10px;
            font-size: 1.5rem;

            @include desktop {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  .services-img {
    height: 15rem;
    width: 15rem;

    @include desktop {
      height: 15rem;
      width: 15rem;
    }

    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
}
