@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.fourohfour {
  display: flex;
  flex-direction: column;
  margin: 3rem;
  align-items: center;
  font-size: 4rem;
  @include desktop {
    margin: 10rem;
  }
  &__link {
    justify-self: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    width: fit-content;
    align-self: center;
    text-decoration: underline;
  }
}
