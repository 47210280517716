@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.erectileDysfunction {
  font-family: "Glacial Indifference";
  font-style: normal;
  font-weight: normal;
  &__hero {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../assets/images/features.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 60%;
    background-attachment: fixed;
    height: 100vh;
  }
  &__title {
    text-align: center;
    font-size: 7rem;
    padding-top: 20%;
    letter-spacing: 0.25rem;
    @media (max-width: 768px) {
      padding-top: 50%;
      font-size: 3rem;
    }
  }
  &__subtitle {
    text-align: center;
    font-size: 2rem;
    padding-top: 4%;
    letter-spacing: 0.25rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  &__container {
    padding: 2rem;
    @include desktop{
      padding: 10rem;
    }

  }
  &__header {
    font-size: 1.5rem;
    padding: 2rem 0rem;
    @include desktop{
      font-size: 2.75rem;
      padding: 2.5rem 0rem 4rem;
    }

    text-align: center;

  }
  &__intro {
    font-size: 1rem;
    @include desktop{
      font-size: 1.5rem;
    }

  }
  &__subheader {
    @include desktop{
      font-size: 2rem;
    }
    font-size: 1.25rem;
    padding: 2rem 0rem 1rem;
  }
  &__pic {
    width: 100%;
    @include desktop {
      width: 30vw;
      float: right;
      margin-left: 5rem;
      margin-bottom: 5rem;
      &--xray{
        @include desktop{
          height: 100%;
          width: 40vw;
          margin-top: 3rem;
        }
  
      }
    }

    &--left{
      @include desktop{
        float: left;
        margin-left: 0%;
        margin-right: 5rem;
        margin-bottom: 5rem;
        margin-top: 2rem;
      }

    }
  }
  &__section{
    @include desktop{
      padding: 2rem 0rem;
      margin-top: 2rem;
    }
    &--shockwave{
      @include desktop{
        display: flex;
        flex-direction: row;
      }

    }

    &--left,&--right{

      @include desktop{
        width: 45%;
      }
    }
  }
  &__content{
    @include desktop{
      font-size: 1.5rem;
    }
    font-size: 1rem;
  }
  &__list{
    &--item{
      padding: 0.5rem 0rem;
      @include desktop{
        padding: 1rem 0rem;
      }

    }
  }
  &__treatment{
    background-color: rgb(243, 207, 115);
    color: black;
  }
  &__box{
    @include desktop{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

  }
  &__bold{
    font-family: "Glacial Indifference Bold";
  }
  &__cynosure{
    @include desktop{
      padding-top: 3rem;
    }

  }
}

.information {
  margin: 4rem 0rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include desktop {
    margin: 3rem 0rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__container {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    margin: 1.5rem 0rem 1rem;
    padding: 0;
    font-size: 1.5rem;
  }

  &__subtitle {
    margin: 0%;
    padding: 0;
    font-size: 1.3rem;
  }

}

#videoContainer {
  position: relative;
  align-self: center;
  width: 100%; // This remains the same for mobile or other views
  padding-bottom: 56.25%; // This gives a 16:9 aspect ratio
  overflow: hidden;

  @include desktop {
    padding-bottom: 16.875%; 
    width: 30vw; // 30% of viewport width
    float: right; // float to the left as you mentioned
    margin-left: 5rem;
    margin-bottom: 5rem;
    margin-top: 3rem;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
