@use "./variables" as *;
@use "./mixins" as *;

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;1,500&family=Montserrat:ital,wght@0,100;0,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital@0;1&display=swap");

@font-face {
  font-family: "Acumin Pro";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Acumin-RPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Acumin-ItPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Acumin-BdPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Acumin-BdItPro.woff") format("woff");
}
